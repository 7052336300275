import React, { useState, useEffect, useRef, Fragment, memo } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    //NavLink,
    Link,
    Redirect,
    useParams,
} from "react-router-dom";
//import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
//import FormCheck from "react-bootstrap/FormCheck";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Table from "react-bootstrap/Table";
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from 'react-bootstrap/Dropdown';
import { LinkContainer } from 'react-router-bootstrap'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { usePageVisibility } from 'react-page-visibility';
import { isIOS } from "react-device-detect";
import * as _ from 'lodash';
import fscreen from 'fscreen';
import { useDebouncedCallback } from 'use-debounce';

import './App.css';
//import { load as load_pip3d, start as pip3d_start, wsrpc, createWebSocket } from "./pip3d";
import { wsrpc, createWebSocket } from "./wsrpc";
import { start as audio_start, stop as audio_stop } from "./audio";
import RemoteConsole from "./RemoteConsole";

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

import { GoogleLogin } from '@react-oauth/google';

const cookieName = process.env.REACT_APP_PIP3D_COOKIE || "session";

const frontend_version = process.env.REACT_APP_FRONTEND_VERSION || "nover";
const workarounds = process.env.REACT_APP_PIP3D_WORKAROUNDS || "";
const iceServers = process.env.REACT_APP_ICE_SERVERS || "";

//const debugLogs = "";
export const dbg = console.log;

function useTraceUpdate(props) {
    /*const prev = useRef(props);
       useEffect(() => {
       const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
       if (prev.current[k] !== v) {
       ps[k] = [prev.current[k], v];
       }
       return ps;
       }, {});
       if (Object.keys(changedProps).length > 0) {
       dbg('Changed props:', changedProps);
       }
       //dbg(prev.current, props);
       prev.current = props;
       });*/
}

const api = {
    add: function(x, y) {
        dbg("add called", x, y);
        return x + y;
    },
};

function hasRole(user, role) {
    return user && _.findIndex(user.roles, r => r.role === role) >= 0;
}

function Error({error}) {
    return <>{error && <span className="validation-error">{error}</span>}</>;
}

const Login = memo(function({ctx, shown, setShown}) {
    const aborter = new AbortController();
    const signal = aborter.signal;
    
    const closeModal = () => setShown(false);
    
    var [ failure, setFailure ] = useState();
    const { register, handleSubmit/*, watch*/, errors } = useForm()

    const apply = handleSubmit(data => {
        setFailure(null);
        ctx.call("validatePassword", data.nick, data.password).catch(setFailure);
    });
    
    useEffect(() => {
        return () => {
            aborter.abort();
        };
    });

    const googleOnSuccess = async (response) => {
        //dbg(response);
        try {
            if (signal.aborted)
                return;
            // todo make this cancellable
            const res = await ctx.call("validateGoogleToken",
                                       response.credential);
            if (signal.aborted)
                return;
            if (res) {
                setFailure(null);
                return;
            } else
                setFailure("unknown error");
        } catch(e) {
            dbg(e);
            setFailure(e.toString());
        }
    }
    
    const googleOnFailure = (response) => {
        dbg("failure", response);
        setFailure(response.error);
    }

    return (
        <Modal show={shown} onHide={closeModal}>
            <Form onSubmit={apply}>
                <Modal.Header closeButton>
                    <Modal.Title>Pick a login method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GoogleLogin
                        onSuccess={googleOnSuccess}
                        onError={googleOnFailure}
                    >Google login</GoogleLogin>
                    <hr className="mt-2 mb-2"/>
                    <Error error={failure}/> <br/>
                    <Form.Label>Password based login :</Form.Label>
                    <Form.Group as={Row}>
                        <Col sm="6">
                            <Form.Control type="string" name="nick"
                                        placeholder="nick"
                                        ref={register({required: true})}
                                        isInvalid={errors.nick}
                            />
                            <Form.Control.Feedback type="invalid">
                                cannot be empty
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm="6">
                            <Form.Control type="password" name="password"
                                        placeholder="password"
                                        ref={register({required: true})}
                                        isInvalid={errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                                cannot be empty
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="success">Login</Button>
                    <Button onClick={closeModal} variant="secondary">Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

function Loading({msg}) {
    return (
        <Alert variant="info">
            {msg || "loading"} ...
        </Alert>
    );
}

function QuietLoading({msg}) {
    return <> {msg || "loading"} ... </>;
}

const root = window;
const apply = Reflect.apply;
const host = root.location.host;
const isSsl = root.location.protocol === "https:";
export const rootpath = (process.env.PUBLIC_URL || "") + "/";
//const rootpath = "/" + process.env.PUBLIC_URL.split("/").splice(3).join("/") + "/"; //root.location.pathname.substring(0, root.location.pathname.lastIndexOf("/")) + "/";
const queryParams = new URLSearchParams(root.location.search);

function Checkbox({checked, onChange, children, disabled = false}) {
    return <>
        <label>{children}
        <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled}/></label>
    </>;
}

const resolutions = [ 
    [1280, 720],
    [1920, 1080], 
];

function loadScript(url, onload) {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.onload = () => {
        onload();
        document.body.removeChild(script);
    };
    document.body.appendChild(script);
}

function blobFromString(text, props) {
    const blob = new Blob([text], props)
    return URL.createObjectURL(blob)
}

let recordingStats = null;
function switchStatsRecording() {
    let res = false;
    if (recordingStats && recordingStats.length) {
        const r = [];
        const start = recordingStats[0].timestamp;
        for (let i = 0; i < recordingStats.length ; i++) {
            const ev = recordingStats[i];
            ev.timestamp -= start;
            r.push(ev);
        }
        console.log(r);
        res = r;
    }
    recordingStats = recordingStats? null : [];
    return res;
}

let recordingInputs = null;
function switchInputsRecording() {
    let res = false;
    if (recordingInputs) {
        let mouse_button = 0;
        let r = [];
        for (let i = 0; i < recordingInputs.length; i++) {
            const ev = recordingInputs[i];
            if (ev.type === "mouse") {
                if (mouse_button || ev.mouse_button)
                    r.push(ev);
                mouse_button = ev.mouse_button;
            } else
                r.push(ev);
        }
        for (let i = r.length-1; i >= 0; i--) {
            r[i].delay = i === 0? 0 : 
                (r[i].timestamp - r[i-1].timestamp);
            delete r[i].timestamp;
        }
        console.log(r);
        res = r;
    }
    recordingInputs = recordingInputs? null : [];
    return res;
}

const appExecStyles = {
    body: {
        width: "100%",
        height: "100%",
        overflowY: "hidden",
    }
};
function _InstanceExec(props) {
    useTraceUpdate(props);
    const { allowed, sessionName, clientLatency, latency, instance } = props;

    const [ imageShown, setImageShown ] = useState(false);
    const [ videoShown, setVideoShown ] = useState(false);
    const [ disabledRendering, setDisabledRendering ] = useState(queryParams.has('disabledRendering'));
    const [ compressWs, setCompressWs ] = useState(queryParams.has('compressWs'));
    const [ forceWebGL2, setForceWebGL2 ] = useState(queryParams.has('forceWebGL2'));
    const [ explorer, setExplorer ] = useState(queryParams.has('explorer'));
    const [ audioAvailable, setAudioAvailable ] = useState(true);
    const [ audioEnabled, setAudioEnabled ] = useState(false);
    const [ audioMuted, setAudioMuted ] = useState(true);
    const [ pip3dLoaded, setPip3dLoaded ] = useState(false);
    const [ pip3dLoading, setPip3dLoading ] = useState(false);
    const audioRef = useRef();
    const [ canvas, setCanvas ] = useState();
    const [ container, setContainer ] = useState();
    const [ status, setStatus ] = useState("loading ...");
    const isVisible = usePageVisibility();
    const [ ws, setWs ] = useState();
    const [ appConfig, setAppConfig ] = useState();
    const [ editedAppConfig, setEditedAppConfig ] = useState();
    const [ more, setMore ] = useState(false);
    const [ displayInfo, setDisplayInfo ] = useState();
    const [ tilted, _setTilted ] = useState(false);
    const [ autoFit, setAutoFit ] = useState(false);
    const [ screen, setScreen ] = useState();
    const [ statsRecord, setStatsRecord ] = useState();
    const [ inputsRecord, setInputsRecord ] = useState();

    function setResolution(width, height, density) {
        ws.sendDisplayInfo({ width: width, height: height, density: density });
    }
    function setTilted(tilted) {
        ws.sendDisplayInfo({ rotation: (tilted? 3 : 0) });
        if (tilted)
            setAutoFit(false);
    }
    function toggleFullscreen() {
        if (fscreen.fullscreenElement === null && container) {
            // fscreen.requestFullscreen(canvas);
            fscreen.requestFullscreenFunction(container).call(container, { navigationUI: 'hide' });
        } else {
            fscreen.exitFullscreen();
        }
    }
    function getWindowWidth(pixratio, fullscreen = false) {
        var width;
        if (fullscreen)
            width = window.screen.width;
        else
            width = window.innerWidth;
        return Math.floor(pixratio * width);
    }
    function getWindowHeight(pixratio, fullscreen = false) {
        var height;
        if (fullscreen)
            height = window.screen.height;
        else
            height = window.innerHeight;
        return Math.floor(pixratio * height);
    }
    function getPixelRatio() {
        return window.outerWidth * window.devicePixelRatio / window.innerWidth;
    }
    function getDensity() {
        var density = window.devicePixelRatio;
        if (isIOS)
            density *= window.outerWidth / window.innerWidth;
        return Math.floor(density * 160);
    }
    if (fscreen.fullscreenEnabled) {
        fscreen.addEventListener('fullscreenchange', () => {
            const pixratio = getPixelRatio();
            const density = getDensity();
            const fullscreen = fscreen.fullscreenElement !== null;
            setResolution(
                getWindowWidth(pixratio, fullscreen),
                getWindowHeight(pixratio, fullscreen),
                density);
        }, false);
    }

    function resize() {
        console.log("resize di", displayInfo);
        if (!displayInfo) return;
        const display_width = displayInfo.rotation % 2 ? displayInfo.height : displayInfo.width;
        const display_height = displayInfo.rotation % 2 ? displayInfo.width : displayInfo.height;
        //const width = document.body.getBoundingClientRect().width;
        //const height = document.body.getBoundingClientRect().height;
        const width = window.innerWidth;
        const height = window.innerHeight;
        // const width = canvas.getBoundingClientRect().width;
        // const height = canvas.getBoundingClientRect().height;
        console.log("window size", width, height);
        let w, h;
        if (display_width/display_height*height <= width) {
            h = height;
            w = display_width/display_height*height;
        } else {
            h = display_height/display_width*width;
            w = width;
        }
        canvas.style.width = `${w}px`;
        canvas.style.height = `${h}px`;
    }

    useEffect(() => {
        window.removeEventListener('resize', resize);
        window.addEventListener('resize', resize);
        resize();
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [displayInfo]);

    useEffect(() => {
        if (!pip3dLoaded && !pip3dLoading) {
            setPip3dLoading(true);
            loadScript(explorer? rootpath + "libpip3d_client_with_ui.js" :
                                (instance.STATIC_BASE_URL + "/libpip3d_client.js"), () => {
                loadScript(instance.STATIC_BASE_URL + "/pip3d_final.js", () => {
                    window.PIP3D.load(() => {
                        setPip3dLoaded(true);
                        setPip3dLoading(false);
                    }, { });
                });
            });
        }
    }, [pip3dLoaded, pip3dLoading, setPip3dLoaded, setPip3dLoading, explorer, instance.STATIC_BASE_URL]);

    useEffect(() => {
        if (!canvas || !isVisible || !instance || !pip3dLoaded)
            return;

        for (let i in appExecStyles.body)
            document.body.style[i] = appExecStyles.body[i];
        
        const aborter = new AbortController();
        const signal = aborter.signal;

        function _setStatus(status) {
            setStatus(status);
            if (recordingStats) {
                const stats = status.split(" ").filter(v => v != "");
                if (stats[1] == "FPS") {
                    recordingStats.push({
                        timestamp: Date.now() / 1000, 
                        FPS: Number(stats[0]),
                        down: Number(stats[2]) * 1000,
                        up: Number(stats[4]),
                    });
                }
            }
        }

        const ws = window.PIP3D.start(instance, {
                canvas, 
                keyEventsReceiver: canvas,
                clientLatency: clientLatency * 1000/60,
                latency: latency * 1000/60,
                quotaBytes: 94371840,
                quotaMinSwaps: 30,
                workarounds: workarounds 
                    + (disabledRendering? " DISABLED_RENDERING" : "")
                    + (queryParams.has("workarounds")? " "+queryParams.get("workarounds") : ""),
                forceWebGL2: forceWebGL2 || explorer,
                compressWs,
                onAppConfig: function(appId, config) {
                    if (signal.aborted) return;
                    setAppConfig({appId, config});
                },
                onDisplayInfoUpdate: function (config) {
                    console.log("onDisplayInfoUpdate", config);
                    if (signal.aborted) return;
                    setDisplayInfo(config);
                    _setTilted(config.rotation % 2);
                },
                onError: console.error,
                onInput: function(ev) {
                    if (recordingInputs) {
                        ev.timestamp = Date.now() / 1000;
                        recordingInputs.push(ev);
                    }
                },
            }, status => signal.aborted? console.log(status) : _setStatus(status), signal);
        setWs(ws);
        const stateCb = (state) => {
            if (signal.aborted) return;
            switch (state) {
                case 'connected':
                    setAudioEnabled(true);
                    break;
            }
        };
        if (audio_start(instance, iceServers, stateCb, undefined, 5000) === false)
            setAudioAvailable(false);

        return () => {
            aborter.abort();
            for (let i in appExecStyles.body)
                document.body.style[i] = null;
            ws.close();
            audio_stop();
        }
    }, [allowed, pip3dLoaded, isVisible, clientLatency, latency, instance, setWs, setAppConfig, setStatus, canvas, disabledRendering, compressWs, forceWebGL2, explorer]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.muted = audioMuted;
            audioRef.current.play(); // needed as autoplay seems broken on chrome
        }
    }, [audioMuted]);

    useEffect(() => {
        if (!canvas) return;
        function focusCanvas() {
            if (!editedAppConfig)
                setTimeout(() => canvas.focus(), 1)
        }
        focusCanvas();
        canvas.addEventListener("blur", focusCanvas);
        return () => canvas.removeEventListener("blur", focusCanvas);
    }, [canvas, editedAppConfig]);

    function updateScreen() {
        const pixratio = getPixelRatio();
        const density = getDensity();
        const winwidth = getWindowWidth(pixratio);
        const winheight = getWindowHeight(pixratio);
        console.log(`disp / css ${pixratio} res ${winwidth}x${winheight} density ${density}`);
        setScreen({ pixratio: pixratio, density: density, width: winwidth, height: winheight });
    }
    const debouncedUpdateScreen = useDebouncedCallback(updateScreen, 100);

    useEffect(() => {
        updateScreen();
        window.addEventListener('resize', debouncedUpdateScreen);
        return () => window.removeEventListener('resize', debouncedUpdateScreen);
    }, []);

    useEffect(() => {
        if (!autoFit) return;
        if (tilted)
            setResolution(screen.height, screen.width, screen.density);
        else
            setResolution(screen.width, screen.height, screen.density);
    }, [autoFit, tilted, screen]);

    if (!allowed)
        return <Loading msg={`waiting for authorization (session ${sessionName})`}/>;

    if (!pip3dLoaded)
        return <QuietLoading/>;
    //dbg("latency", props.latency);

    //<div ref={(elt) => elt && elt.requestFullscreen() || document.exitFullscreen()}>
    //<Helmet><title>{app.name}</title></Helmet>
    //{imageShown && <img className="absolute" src={rootpath + "brain.jpg"} alt="Brain"/>}
    return (<div ref={setContainer}>
        {imageShown && <img className="absolute" src={rootpath + "brain.jpg"} alt="Brain"/>}
        {videoShown && <video className="absolute" autoPlay>
            <source src="https://www.n2i.io/static/Sheep.mp4" type="video/mp4"/>
        </video>}
        {audioAvailable && <audio id="audio" autoPlay muted ref={audioRef}></audio>}
        <div className="statusBar">
            {more && 
            //<div>
            <div style = { { backgroundColor: "white", opacity: 0.7 } }>
                {displayInfo && <>
                    { resolutions.map(([width, height]) => <Fragment key={""+width+"x"+height}>
                            <SmallButton onClick={() => { setAutoFit(false); setResolution(width, height, screen.density) }}>
                                {""+width+"x"+height}</SmallButton>
                        </Fragment>) }
                    <SmallButton onClick={() => setResolution(screen.width, screen.height, screen.density)} disabled={autoFit}>
                            Fit window</SmallButton>
                    <SmallButton onClick={() => toggleFullscreen()} disabled={!fscreen.fullscreenEnabled}>
                        Fullscreen</SmallButton>
                    <br/>
                    {displayInfo.width} x {displayInfo.height} density {displayInfo.density} rotation {displayInfo.rotation}
                </>}
                <br/>
                <Checkbox checked={autoFit} onChange={e => setAutoFit(e.target.checked)}>
                    Auto fit</Checkbox>
                <Checkbox checked={disabledRendering} onChange={e => setDisabledRendering(e.target.checked)}>
                     | Disabled rendering </Checkbox>
                <Checkbox checked={compressWs} onChange={e => setCompressWs(e.target.checked)}>
                     | Use websocket compression </Checkbox>
                <Checkbox checked={forceWebGL2} onChange={e => setForceWebGL2(e.target.checked)}>
                     | Force WebGL2 </Checkbox>
                <Checkbox checked={explorer} onChange={e => { setExplorer(e.target.checked); setPip3dLoaded(false); }}>
                     | PIP3D Explorer </Checkbox>
                <Checkbox checked={statsRecord === false} onChange={e => { setStatsRecord(switchStatsRecording()); }}> | Stats recording </Checkbox>
                { statsRecord && <a download={appConfig.appId+"-stats.json"} type="application/octet-stream" 
                    href={blobFromString(JSON.stringify(statsRecord),
                        { type: "application/octet-stream"})}>Download</a> }
                <Checkbox checked={inputsRecord === false} onChange={e => { setInputsRecord(switchInputsRecording()); }}> | Recording inputs </Checkbox>
                { inputsRecord && <a download={appConfig.appId+"-inputs.json"} type="application/octet-stream" 
                    href={blobFromString(JSON.stringify(inputsRecord),
                        { type: "application/octet-stream"})}>Download</a> }
                <br/>
                <Checkbox checked={imageShown} onChange={e => setImageShown(e.target.checked)}>
                    Background image </Checkbox>
                <Checkbox checked={videoShown} onChange={e => setVideoShown(e.target.checked)}>
                     | Background video </Checkbox>
                { appConfig && <SmallButton onClick={() => setEditedAppConfig(appConfig)}>{appConfig.appId}</SmallButton> }
                <SmallButton onClick={() => setEditedAppConfig({
                    appId: "surfaceflinger", 
                    config: "max_fbhandle_depth=0\nkeep_live_dbg=no\n"
                    })}>surfaceflinger</SmallButton>
                <br/>
            </div>}
            <Checkbox checked={more} onChange={(e) => setMore(e.currentTarget.checked)}>
                More</Checkbox>
            <Checkbox checked={tilted} onChange={e => setTilted(e.target.checked) }>
                | Tilted</Checkbox>
            {audioAvailable && <Checkbox checked={!audioMuted} disabled={!audioEnabled} onChange={e => setAudioMuted(!e.target.checked)}>
                | Audio</Checkbox>}
            <SmallButton onClick={() => {ws.injectKeyEvent(0x66, 1); ws.injectKeyEvent(0x66, 0); }}>Home</SmallButton>
            <SmallButton onClick={() => {ws.injectKeyEvent(1, 1); ws.injectKeyEvent(1, 0); }}>Back</SmallButton>
            <AppConfigModal appConfig={editedAppConfig} close={() => setEditedAppConfig()} ws={ws} />
            <mark className="inline"> {status} </mark>
        </div>
        <canvas tabIndex="1" id="canvas" ref={setCanvas}/>
        {/*<mark id="console"> </mark>*/}
    </div>);
}
// we don't want to re render on latency change
const InstanceExec = memo(_InstanceExec, (a, b) => 
    a.allowed === b.allowed 
    && a.instance.name === b.instance.name
    && a.instance.status === b.instance.status);

function InstanceExecPage(props) {
    // useParams cause the component to be re-rendered every time, so we lift its usage
    // in a *Page component
    const { slug } = useParams();
    for (let instance of props.instances) {
        if (instance.name === slug) {
            if (instance.status !== "running")
                return <Alert variant="danger"> Instance {slug} is not running. </Alert>
            return <InstanceExec {...props} instance={instance} />;
        }
    }
    return <Alert variant="danger"> Instance {slug} does not exist. </Alert>
}

function AppConfigModal({appConfig, close, ws}) {
    const { register, handleSubmit/*, watch*/, errors } = useForm()
    const [ s, setS ] = useState(); /* this is used to have proper fadout animation */

    const apply = handleSubmit(function(data) {
        //console.log(s, appConfig, data);
        if (appConfig.config !== data.config) {
            ws.sendAppConfig(s.appId, data.config);
            appConfig.config = data.config;
        }
        close();
    });

    if (appConfig && s !== appConfig)
        setS(appConfig);

    return <>
        {s && <Modal show={!!appConfig} onHide={close} onExited={() => setS(appConfig)}>
            <Form onSubmit={apply}>
                <Modal.Header closeButton>
                    <Modal.Title>Configuration for <i>{s.appId}</i></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control as="textarea" type="string" name="config"
                                        rows={20}
                                        ref={register({minLength: 0, maxLength: 4*1024})}
                                        defaultValue={s.config}
                                        isInvalid={errors.config}
                        />
                        <Form.Control.Feedback type="invalid">
                            config should be less than 4KB of text data
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={close} variant="secondary">Cancel</Button>
                    <Button type="reset" variant="secondary">Reset</Button>
                    <Button type="submit" variant="success">Apply</Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>;
}


function SimpleTooltip({tooltip, children}) {
    return <OverlayTrigger
               placement="right"
               delay={{ show: 250, hide: 400 }}
               overlay={props => <Tooltip {...props} show={String(props.show)}>{tooltip}</Tooltip>}
           >
        {children}
    </OverlayTrigger>;
}

function ControllerRow({ctx, controller}) {
    //console.log("controller", controller);
    const imageTags = (controller.imageTags || "").split("\n");
    const [curTag, setTag] = useState(imageTags[0]);
    return <tr key={controller.name}>
        <td>{controller.name}</td>
        <td>
            {" "}<SmallButton onClick={() => ctx.call("spawnInstance", controller.name, {
                imageTag: curTag !== ""? (":"+curTag) : undefined
            })}>Spawn</SmallButton>
        </td>
        <td>{controller.capacity - controller.available} / {controller.capacity}</td>
        <td>{controller.connected? "connected" : "disconnected"}</td>
        {imageTags.length > 1 && <Dropdown onSelect={setTag} >
            <Dropdown.Toggle id="dropdown-basic" className="btn-sm py-0 px-1">
                {curTag}
            </Dropdown.Toggle>

            <Dropdown.Menu> {imageTags.map(tag => 
                <Dropdown.Item key={tag} eventKey={tag} active={tag===curTag}>{tag}</Dropdown.Item>
            )}
            </Dropdown.Menu>                
        </Dropdown>}
    </tr>
}

function InstanceSelect(props) {
    useTraceUpdate(props);
    const {ctx, instances, controllers, user} = props;
    
    if (!instances || !controllers)
        return (<QuietLoading/>);

    const controllersRows = hasRole(user, "admin") && controllers.map((controller) => 
        <ControllerRow key={controller.name} ctx={ctx} controller={controller}></ControllerRow>
    );
        
    const rows = instances.map((instance) =>
        <tr key={instance.name}>
            <td><Link to={"/instance/" + instance.name}>
                {instance.name} </Link></td>
            {hasRole(user, "admin") && <>
                <td> adb port = {instance.ADB_PORT} </td>
                <td> <SmallButton onClick={() => ctx.call("deleteInstance", instance.name)}>
                    Destroy </SmallButton>
                    {instance.sessions && instance.sessions.length > 0 && <><br/><small>{ instance.sessions.map((session, i) => [
                            i === 0? "used by " : ", ",
                            <SimpleTooltip key={i} tooltip="click to kill">
                                <Badge variant="info"
                                    onClick={() => ctx.call("stopApp", session) }>
                                    {session}</Badge>
                            </SimpleTooltip>
                    ]) }</small></> }
                </td>
            </>}
            <td>{instance.status}
                {instance.status === "preallocated" && <><SmallButton onClick={() => ctx.call("modifyInstance", instance.name, false)}>
                    Use </SmallButton></>}</td>
        </tr>
    );
    return <>
        {hasRole(user, "admin") && <>
        <h3>Controllers</h3>
        <Table striped bordered className="table-nonfluid" size="sm">
            <tbody>
                {controllersRows}
            </tbody>
        </Table>
        <h3>Instances</h3>
        </>}
        <Table striped bordered className="table-nonfluid" size="sm">
            <tbody>
                {rows}
            </tbody>
        </Table>
    </>;
}

function Bold({bold, children}) {
    return bold?
           (<b> {children} </b>)
         : children;
}

function SmallButton(props) {
    return <Button {...props} className="btn-sm py-0 px-1" />;
}

var toggleId = 0;
function SwitchButton({value, onToggle, label}) {
    // cf https://github.com/react-bootstrap/react-bootstrap/issues/4767
    return <Form.Switch inline checked={value} label={label || ""} onChange={e => onToggle(!e.target.checked)}
                        id={`toggleid-${toggleId++}`} />;
    /*return <Form.Check inline type="checkbox" checked={value}
       onChange={e => onToggle(!e.target.checked)} />;*/

    /*return (
       <ReactSwitch
       checked={value}
       onChange={state => onToggle(!state)}
       handleDiameter={20}
       uncheckedIcon={false}
       checkedIcon={false}
       boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
       activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
       height={15}
       width={40}
       className="react-switch"
       />);*/
}

function ExpiringToggleButton({ value, onToggle, initialDuration }) {
    var strLeft = "", nextIn;
    const [ refresh, setRefresh ] = useState(0);
    const now = Date.now();
    const timeLeft = value - now;
    const limits = [
        { mult: 24 * 3600 * 1000, str: "days" },
        { mult: 3600 * 1000, str: "hours" },
        { mult: 60 * 1000, str: "minutes" },
        { mult: 1000, str: "seconds", last: true },
    ];
    for (var { mult, str, last } of limits) {
        if ((last && timeLeft > 0) || timeLeft > 2*mult) {
            const unitsLeft = Math.floor(timeLeft / mult);
            strLeft = `${unitsLeft + 1} ${str}`;
            dbg(strLeft);
            nextIn = timeLeft - unitsLeft * mult + 1;
            break;
        }
    }
    useEffect(() => {
        const timer = nextIn &&
                      setTimeout(() => setRefresh(refresh + 1), nextIn);
        return () => timer && clearTimeout(timer);
    });
    return (
        <Fragment>
            <SwitchButton
                value={ !!value }
                onToggle={state => {
                        onToggle(state? false : (Date.now() + initialDuration*60*1000));
                } }
            />
            {strLeft && <><small>
                {" "}expires in {strLeft + " "}
                <ButtonGroup size="sm">
                    <SmallButton variant="success" onClick={() => onToggle(value - 5*60*1000 <= Date.now()?
                                                                           false : value - 5*60*1000)}>
                        <b>-</b>5m
                    </SmallButton>
                    <SmallButton variant="success" onClick={() => onToggle(value + 5*60*1000)}>
                        <b>+</b>5m
                    </SmallButton>
                </ButtonGroup>
                {" "}
                <ButtonGroup size="sm">
                    <SmallButton variant="success" onClick={() => onToggle(value - 24*60*60*1000 <= Date.now()?
                                                                           false : value - 24*60*60*1000)}>
                        <b>-</b>1d
                    </SmallButton>
                    <SmallButton variant="success" onClick={() => onToggle(value + 24*60*60*1000)}>
                        <b>+</b>1d
                    </SmallButton>
                </ButtonGroup>
            </small></>}
        </Fragment>
    );
}

function SessionAdminModal({ctx, session, close, instances}) {
    const { register, handleSubmit/*, watch*/, errors } = useForm()
    const [ s, setS ] = useState();

    const apply = handleSubmit(function(data) {
        //console.log(data);
        ctx.call("setClientLatency", session.sessionName, data.clientLatency);
        ctx.call("setLatency", session.sessionName, data.latency);
        ctx.call("setSimpleClientInstance", session.sessionName, data.instance);
        close();
    });

    if (session && s !== session)
        setS(session);

    return <>
        {s && <Modal show={!!session} onHide={close} onExited={() => setS(session)}>
            <Form onSubmit={apply}>
                <Modal.Header closeButton>
                    <Modal.Title>More options ({s.sessionName})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">Client latency</Form.Label>
                        <Col sm="10">
                            <Form.Control type="number" name="clientLatency"
                                          ref={register({required: true, min: 0, max:200})}
                                          defaultValue={s.clientLatency}
                                          isInvalid={errors.clientLatency}
                            />
                            <Form.Control.Feedback type="invalid">
                                please enter an integer between 1 and 200
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">Latency</Form.Label>
                        <Col sm="10">
                            <Form.Control type="number" name="latency"
                                          ref={register({required: true, min: 1, max:200})}
                                          defaultValue={s.latency}
                                          isInvalid={errors.latency}
                            />
                            <Form.Control.Feedback type="invalid">
                                please enter an integer between 1 and 200
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">Instance</Form.Label>
                        <Col sm="10">
                            <Form.Control as="select" name="instance" ref={register} defaultValue={s.simpleClientInstance} >
                                <option key="" value={null}></option>
                                {instances.map(instance => 
                                    <option key={instance.name}> {instance.name} </option>)}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={close} variant="secondary">Cancel</Button>
                    <Button type="reset" variant="secondary">Reset</Button>
                    <Button type="submit" variant="success">Apply</Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>;
}

function SessionAdminRow({ctx, sessionName, session, setEditedSession}) {

    const ConditionalLink = ({ children, to, condition }) => (!!condition && to)
        ? <Link to={to}>{children}</Link>
        : <>{children}</>;

    return <tr>
        <td>
            <Bold bold={session.sessionName === sessionName}>
                <ConditionalLink to={`/simple/?sessionId=${session.sessionId}`} condition={!session.nick}>
                    {session.sessionName}
                </ConditionalLink> {session.nick && <small>({session.nick})</small>}
            </Bold>
            <small> ({session.nbContexts}) </small>
        </td>
        <td>{session.userAgent.browser + "-" + session.userAgent.version}</td>
        <td>{session.userAgent.platform}</td>
        <td>{session.ip}</td>
        <td>{(new Date(session.startTs)).toLocaleString()}</td>
        <td>
            {session.nick? (
                 <SwitchButton
                     value={ !!session.allowed }
                     onToggle={state => ctx.call("setAllowed", session.sessionName, !state)}
                 />)
             : (
                 <ExpiringToggleButton
                     value={ session.allowed }
                     onToggle={state => ctx.call("setAllowed", session.sessionName, state)}
                     initialDuration={10}
                 />)
            }
        </td>
        <td>
            <SmallButton onClick={() => setEditedSession(session)}>More...</SmallButton>
        </td>
    </tr>;
}

function Admin(props) {
    useTraceUpdate(props);
    const {ctx, user, instances} = props;
    
    const [ sessions, setSessions ] = useState();
    const [ editedSession, setEditedSession ] = useState();

    useEffect(() => {
        if (user) {
            api.setSessions = setSessions;
            ctx.call("getSessions")
               .then(setSessions,
                     err => {
                         dbg(err);
                         setSessions(null);
                     });
        }
        return () => {
            api.setSessions = function() {};
        };
    }, [ctx, user]);

    if (!user)
        return <Redirect to='/' />;

    if (!sessions)
        return <QuietLoading/>;

    //dbg(sessions);

    return <>
        <Helmet><title>Admin</title></Helmet>
        <Table striped bordered className="table-nonfluid" size="sm">
            <thead>
                <tr>
                    <th>Session <small>(tabs)</small></th>
                    <th>Browser</th>
                    <th>Platform</th>
                    <th>Ip</th>
                    <th>Created</th>
                    <th>Authorized</th>
                </tr>
            </thead>
            <tbody>
                {sessions.map(s =>
                    <SessionAdminRow {...props} key={s.sessionName} session={s}
                                     setEditedSession={setEditedSession} />)}
            </tbody>
        </Table>
        <SessionAdminModal ctx={ctx} session={editedSession} instances={instances} close={() => setEditedSession(null)} />
    </>;
}

const selfTests = [
    {
        desc: "WebAssembly",
        test() {
            return "WebAssembly" in window;
        }
    },
    {
        desc: "WebSocket",
        test() {
            return "WebSocket" in window;
        }
    },
];

const testRows = selfTests.map((test) =>
    <tr key={test.desc}>
        <td>{test.desc}</td>
        <td>{test.test()?
             <span style={{ color: 'green' }}>OK</span> :
             <span style={{ color: 'red' }}>FAILED</span>}</td>
    </tr>
);
function SelfTests() {
    return <>
        <Table striped bordered className="table-nonfluid" size="sm">
            <tbody>
                {testRows}
            </tbody>
        </Table>
    </>;
}

function NavLink({children, ...props}) {
    return (
        <LinkContainer {...props}>
            {/* force active to false to prevent two links to be active concurrently
              * cf. https://github.com/react-bootstrap/react-router-bootstrap/issues/242 */}
            <Nav.Link active={false}>{children}</Nav.Link>
        </LinkContainer>
    );
}

function SessionInfo(props) {
    const {ctx, sessionName, user, setLoginShown} = props;
    return <Navbar.Collapse className="justify-content-end">
        <Navbar.Text className="py-1">
            frontend version : {frontend_version}
            {' | '}
            {!user?
             <SmallButton size="sm" variant="secondary"
                          onClick={() => setLoginShown(true)}>
                 Login</SmallButton>
             :<>{user.nick} <SmallButton size="sm" variant="secondary"
                                         onClick={() => ctx.call("logout")}>
                 (Logout)</SmallButton>
             </>
            }
            {' | '}this session: <Badge variant="info">{sessionName}</Badge>
        </Navbar.Text>
    </Navbar.Collapse>;
}

function App() {

    const [ ctx, setCtx ] = useState(null);

    const [ user, setUser ] = useState(null);
    const [ cookies, setCookie/*, removeCookie*/ ] = useCookies([cookieName]);
    const [ sessionId, setSessionId ] = useState();
    const [ sessionName, setSessionName ] = useState();
    const [ allowed, setAllowed ] = useState();
    const [ clientLatency, setClientLatency ] = useState();
    const [ latency, setLatency ] = useState();
    const [ instances, setInstances ] = useState();
    const [ controllers, setControllers ] = useState();
    const [ loginShown, setLoginShown ] = useState(null);

    const cookie = queryParams.has('sessionId') ? queryParams.get('sessionId') : cookies[cookieName];
    useEffect(() => {
        const ws = createWebSocket(root.location.protocol + "//" + host + rootpath + "api");

        const ctx = wsrpc(ws, api);

        ws.onopen = function() {
            dbg("opened");
            setCtx(ctx);

            api.setUser = user => { setUser(user); if (user) setLoginShown(false); };
            api.setAllowed = setAllowed;
            api.setClientLatency = setClientLatency;
            api.setLatency = setLatency;
            api.setInstances = setInstances;
            api.setControllers = setControllers;
            api.setSimpleClientInfo = function() { };
            api.setSessions = function() { };

            api.onReady = function () {
                dbg("setOrCreateSession", cookie);
                ctx.call("setOrCreateSession", cookie)
               .then(([sessionId, sessionName]) => {
                   //dbg(sessionId, user);
                   if (sessionId !== cookie) {
                       dbg("new session", sessionId);
                       setCookie(cookieName, sessionId, {
                           path: "/",
                           /* without maxAge, the cookie will be reset when the browser restart,
                            * which is fine after all */
                           //maxAge: 3600*24*30,
                           //httpOnly: true,
                           sameSite: "none",
                           secure: true,
                       } );
                   }
                   setSessionName(sessionName);
                   setSessionId(sessionId); // do this last
               }, err => dbg(err));
            }
        }

        ws.onclose = function() {
            dbg("closed");
            setCtx(null);
            // TODO handle reconnect ?
            api.setUser
            = api.setAllowed
            = api.setClientLatency
            = api.setLatency
            = api.setInstances
            = api.setSimpleClientInfo
            = function() {};
        }

        return () => {
            ws.close();
        }
    }, [cookie, setCookie]);

    if (!sessionId || !ctx) {
        return (
            <QuietLoading msg="connecting"/>
        );
    }

    const props = { ctx, sessionName };

    return (<HelmetProvider>
        <Router basename={rootpath}>
            <Switch>
                <Route exact path="/instance/:slug">
                    <InstanceExecPage {...props} allowed={allowed} clientLatency={clientLatency} latency={latency} instances={instances} />
                </Route>
                <Route path="/">
                    <Navbar bg="dark" variant="dark" sticky="top" className="py-1">
                        <Navbar.Brand href="/">N2i demo</Navbar.Brand>
                        <Nav>
                            <NavLink exact to="/">Instances</NavLink>
                            <NavLink exact to="/tests">Tests</NavLink>
                            {hasRole(user, "admin") &&
                             <NavLink exact to="/admin">Admin</NavLink>
                            }
                            {hasRole(user, "admin") &&
                            <NavLink exact to="/console">Console</NavLink>
                            }
                        </Nav>
                    </Navbar>

                    <Login {...props} shown={loginShown} setShown={setLoginShown} />
                    
                    <div className="p-2 overflow-auto">
                        <Switch>
                            <Route exact path="/tests">
                                <Helmet><title>Tests</title></Helmet>
                                <SelfTests />
                            </Route>
                            <Route exact path="/admin">
                                <Helmet><title>Admin</title></Helmet>
                                <Admin {...props} user={user} instances={instances} />
                            </Route>
                            <Route exact path="/console">
                                <Helmet><title>Remote Console</title></Helmet>
                                <RemoteConsole {...props} user={user} instances={instances}/>
                            </Route>
                            <Route exact path="/">
                                <Helmet><title>N2i demo</title></Helmet>
                                <InstanceSelect {...props} user={user} instances={instances} controllers={controllers} />
                            </Route>
                            <Route path="/">
                                <Redirect to='/' />
                            </Route>
                        </Switch>
                    </div>
                    
                    <Navbar bg="dark" variant="dark" fixed="bottom" className="navbar-expand-sm py-0">
                        <SessionInfo {...props} user={user} setLoginShown={setLoginShown} />
                    </Navbar>
                </Route>
            </Switch>
        </Router>
    </HelmetProvider>);
}

export default App;
